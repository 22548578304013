import type { IsActiveMatchOptions } from '@angular/router';
import { RouterModule } from '@angular/router';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export interface InterfaceComponentLinksMenu<URLs = string> {
  url: URLs;
  title:
    | 'Versicherer'
    | 'Tarifbereiche'
    | 'Tarife'
    | 'Attribute'
    | 'Berufelisten'
    | 'Fondslisten'
    | 'Konstanten';
  hidden?: boolean;
}

export const LINK_MATCHER: IsActiveMatchOptions = {
  paths: 'subset',
  fragment: 'ignored',
  matrixParams: 'ignored',
  queryParams: 'ignored',
};

@Component({
  selector: 'ui-header',
  standalone: true,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterModule],
})
export class HeaderComponent {
  routerLinkActiveOptions = LINK_MATCHER;

  trackById = (i: number) => i;

  @Input() links?: InterfaceComponentLinksMenu[] | null = [];
}
