import { AsyncPipe, CommonModule } from '@angular/common';
import type { AfterViewInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  forwardRef,
  inject,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  BehaviorSubject,
  ReplaySubject,
  combineLatest,
  filter,
  fromEvent,
  map,
  merge,
  of,
  startWith,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { ControlValueAccessorDirective } from '../../directives/control-value-accessor.directive';
import { MapPipe } from '../../pipes/map.pipe';
import { SnackbarService } from '../../services/snackbar.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DividerComponent } from '../divider/divider.component';
import { ToSizeUnitPipe } from '../../pipes/to-size-unit.pipe';

export interface UploadInfo {
  status?: 'success' | 'error' | 'progress';
  progress?: number;
  uploadedDocuments?: UploadDocument[];
}
interface UploadDocument {
  id: string;
  name: string;
}

enum FileType {
  svg = 'image/svg+xml',
  csv = 'text/csv',
  pdf = 'application/pdf',
}

interface UploadBoxState {
  dragging: boolean;
  disabled: boolean;
  value: File | null;
  mediaTypes: string | null;
}

function stopEvent<E extends Event>(event: E) {
  event.stopPropagation();
  event.preventDefault();
}

@Component({
  selector: 'ui-upload-box-rx',
  standalone: true,
  imports: [
    CommonModule,
    AsyncPipe,
    MatSnackBarModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    MapPipe,
    DividerComponent,
    ToSizeUnitPipe,
  ],
  templateUrl: './upload-box-rx.component.html',
  styleUrls: ['./upload-box-rx.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadBoxRxComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadBoxRxComponent
  extends ControlValueAccessorDirective<File | File[] | string | string[] | null>
  implements AfterViewInit
{
  @ViewChild('dropbox') dropbox!: ElementRef;
  private snackBar = inject(SnackbarService);

  acceptableMediaTypes$ = new ReplaySubject<string[]>(1);
  value$ = new ReplaySubject<File | null>(1);
  state$ = new BehaviorSubject<UploadBoxState>({
    dragging: false,
    disabled: false,
    value: null,
    mediaTypes: null,
  });
  fileType = FileType;

  uploadInfo$ = new BehaviorSubject<UploadInfo | null>(null);
  private _maxFileSizeMb: number | undefined;

  @Output() fileName = new EventEmitter<string>();
  @Output() cancelUpload = new EventEmitter<boolean>();
  @Output() resetUpload = new EventEmitter<boolean>();
  @Output() deleteDocument = new EventEmitter<string>();

  @Input()
  set maxFileSizeMb(value: number | undefined) {
    this._maxFileSizeMb = value;
  }
  get maxFileSizeMb(): number | undefined {
    return this._maxFileSizeMb;
  }
  get maxFileSizeByte(): number | undefined {
    return (this._maxFileSizeMb || 0) * 1024 * 1024;
  }
  @Input() label = 'Datei auswählen';
  @Input() returnType: 'base64' | 'file' = 'base64';
  @Input()
  set uploadInfo(value: UploadInfo | null) {
    this.uploadInfo$.next(value);
  }
  @Input({ required: true })
  set mediaTypes(mediaTypes: string[]) {
    this.acceptableMediaTypes$.next(mediaTypes);
  }

  getImagePreview = (value: File | null) => this.convertFileToBase64(value);

  ngAfterViewInit() {
    const nativeElement = this.dropbox.nativeElement;

    const dragenter$ = fromEvent(nativeElement, 'dragenter').pipe(map(() => true));
    const dragleave$ = fromEvent(nativeElement, 'dragleave').pipe(map(() => false));
    const dragover$ = fromEvent<DragEvent>(nativeElement, 'dragover').pipe(
      tap(stopEvent),
      map(() => true)
    );
    const drop$ = fromEvent<DragEvent>(nativeElement, 'drop').pipe(
      tap(stopEvent<DragEvent>),
      map(event => event.dataTransfer?.files.item(0) || null)
    );

    const dropWhileNotDisabled$ = drop$.pipe(
      withLatestFrom(this.disabled$),
      filter(([, disabled]) => !disabled),
      map(([value]) => value)
    );

    const dragging$ = merge(dragenter$, dragleave$, dragover$).pipe(startWith(false));
    const disabled$ = this.disabled$.pipe(startWith(false));

    const acceptableMediaTypes$ = this.acceptableMediaTypes$.pipe(startWith([] as string[]));

    const value$ = combineLatest([
      merge(dropWhileNotDisabled$, this.value$),
      acceptableMediaTypes$,
    ]).pipe(
      map(([value, acceptableMediaTypes]) => {
        if (!value) return value;

        if (acceptableMediaTypes.length) {
          const isValidMediaType = acceptableMediaTypes.some(mediaType => {
            if (mediaType === value.type) {
              return true;
            }
            if (mediaType.endsWith('/*')) {
              const baseType = mediaType.split('/')[0];
              return value.type.startsWith(baseType + '/');
            }
            return false;
          });
          if (!isValidMediaType) {
            this.snackBar.error('Die Datei ist vom falschen Typ.');
            return null;
          }
        }
        if (this.maxFileSizeByte && value.size > this.maxFileSizeByte) {
          this.snackBar.error(
            `Das Dokument darf die Dateigröße von ${this.maxFileSizeMb} mb nicht überschreiten`
          );
          return null;
        }
        return value;
      }),
      tap(() => this.onTouched()),
      switchMap(value => {
        if (value instanceof File) {
          if (this.returnType === 'base64') {
            this.convertFileToBase64(value)
              .then(file => this.onChange(file))
              .catch(() => this.onChange(null));
          } else {
            this.onChange(value);
          }
        }
        return of(value);
      }),
      tap(file => {
        if (file && file instanceof File && this.returnType === 'base64') {
          this.fileName.emit(file.name.split('.')[0]);
        }
      }),
      startWith(null)
    );

    combineLatest([dragging$, disabled$, value$, acceptableMediaTypes$])
      .pipe(
        map(([dragging, disabled, value, acceptableMediaTypes]) =>
          this.state$.next({
            dragging,
            disabled,
            value,
            mediaTypes: acceptableMediaTypes.length ? acceptableMediaTypes.join(', ') : null,
          })
        )
      )
      .subscribe();
  }

  onFileInputChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const file = target.files?.[0];
    if (file?.size) this.value$.next(file || null);
    target.value = '';
  }

  removeFile() {
    this.value$.next(null);
  }

  override writeValue(value: File | string | null): void {
    super.writeValue(value);
    if (typeof value == 'string') {
      this.convertBase64ToFile(value)
        .then(file => this.value$.next(file))
        .catch(() => this.value$.next(null));
    } else {
      this.value$.next(value);
    }
  }

  private convertFileToBase64(file: File | null): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!file) return reject(new Error('No file provided'));

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result as string;
        const base64String = result.split(',')[1];
        resolve(base64String);
      };
      reader.onerror = error => reject(error);
    });
  }

  private async convertBase64ToFile(base64String: string | null): Promise<File | null> {
    if (!base64String) return null;

    try {
      const mediaType = 'image/svg+xml';
      const base64Response = await fetch(`data:${mediaType};base64,${base64String}`);
      const blob = await base64Response.blob();

      return new File([blob], this.label, { type: mediaType });
    } catch (error) {
      console.error('Error converting base64 to file:', error);
      return null;
    }
  }
}
