import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'ui-stage-info',
  imports: [MatIcon],
  templateUrl: './stage-info.component.html',
  styleUrl: './stage-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StageInfoComponent {
  @Input() icon?: string;
}
