import { isEqualWith } from 'lodash';
import type { OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  DestroyRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BehaviorSubject, Observable, filter, from, startWith, switchMap, take } from 'rxjs';
import type { FormControl, ValidatorFn } from '@angular/forms';
import { AbstractControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { controlMarkAsTouched } from '../../misc/formMarkAllAsTouched';

export interface waitForObj<T> {
  waitFor: (subscription: Observable<unknown> | Promise<unknown>) => void;
  data: { value: T; version: number };
}

@Component({
  selector: 'ui-inline-edit',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
  ],
  templateUrl: './inline-edit.component.html',
  styleUrls: ['./inline-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineEditComponent<T> implements OnInit {
  fg = new FormGroup({}); // for form submit

  @Input() public enabled = true; // Enable inline-editing

  /**
   * Feature to add an read mode to the inline-edit
   * Example:
   * <ui-inline-edit [enableReadonlyMode]="true" [control]="ctrl.name" [default]="data.name.value"
        (save)="$event.waitFor(...)">
        <ng-template #read>
            <ui-inline-value label="Attribut Name">
                {{ctrl.name.value}}
            </ui-inline-value>
        </ng-template>
        <mat-form-field>
            <mat-label>Attribut Name</mat-label>
            <input matInput [formControl]="ctrl.name" />
            <mat-error ui-input-errors [control]="ctrl.name"></mat-error>
        </mat-form-field>
    </ui-inline-edit>
   */
  @Input() public enableReadonlyMode? = false;

  @ContentChild('read') readChild?: TemplateRef<T>;

  @Input() padding = false; // later feature..

  @Input({ required: true }) version = 0;

  /**
   * Observable to execute
   */
  @Output() save: EventEmitter<waitForObj<T>> = new EventEmitter();

  @Output() saveSuccess: EventEmitter<void> = new EventEmitter();

  checkChange$ = new BehaviorSubject<void>(undefined);
  valueChanged$ = new BehaviorSubject(false);

  /**
   * Input settings
   */
  _control!: AbstractControl;
  get control() {
    return this._control;
  }
  @Input() set control(control: AbstractControl) {
    this._control = control;
    this.checkChange$.next();
  }

  private _defaultValue: unknown;
  set default(data: unknown) {
    this._defaultValue = data;
    this.checkChange$.next();
  }
  get default() {
    return this._defaultValue ?? (<FormControl>this.control).defaultValue;
  }

  private _destroyRef = inject(DestroyRef);
  loading$ = new BehaviorSubject(false);
  editStatus$ = new BehaviorSubject(true);

  fn!: ValidatorFn;

  ngOnInit() {
    if (this.enableReadonlyMode) {
      // if switchModes is true, editMode is false
      this.editStatus$.next(false);
    }

    if (this.default === undefined) {
      this.default = this.control.value;
    }

    /**
     * Stream for change
     */
    this.checkChange$
      // emit check also on control value change
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        switchMap(() => this.control.valueChanges.pipe(startWith(this.control.value)))
      )
      .subscribe(() => {
        //make sure, that we compare the raw
        const change = !isEqualWith(
          this.control.getRawValue(),
          this.default,
          this.ignoreArrayOrder
        );
        this.valueChanged$.next(change);
      });

    /**
     * Switch Controls to enable & disable only if switchmode is
     * on
     */
    this.editStatus$
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter(() => !!this.enableReadonlyMode)
      )
      .subscribe(editMode => {
        if (editMode) {
          this.control.enable();
        } else {
          this.control.disable();
        }
      });
  }

  private ignoreArrayOrder(value1: any, value2: any) {
    if (Array.isArray(value1) && Array.isArray(value2)) {
      return (
        value1.length === value2.length &&
        value1.every(value => {
          return value2.indexOf(value) > -1;
        })
      );
    }
    return undefined;
  }

  readonly() {
    if (this.enableReadonlyMode) {
      this.editStatus$.next(!this.editStatus$.value);
    }
  }

  doSave() {
    const emit: waitForObj<T> = {
      data: { value: this.control.value, version: this.version },
      waitFor: subscription => {
        this.control.disable();

        // convert promise to observable
        if (subscription instanceof Promise) {
          subscription = from(subscription);
        }

        this.loading$.next(true);

        if (this.fn) {
          this.control.removeValidators(this.fn);
        }

        subscription.pipe(take(1)).subscribe({
          next: response => {
            this.control.enable();
            this.readonly();
            this.loading$.next(false);

            // this.saveSuccess.emit();
            const newValue = this.control.value;

            this.default = newValue;
            this.control.setValue(newValue);
            this.version = (response as { version: number }).version;
            this.checkChange$.next();
          },
          error: (e: Error) => {
            this.fn = () => {
              return {
                custom: e.message ?? e,
              };
            };

            if (e) {
              this.control.setValidators(this.fn);
            } else {
              this.control.removeValidators(this.fn);
            }
            controlMarkAsTouched(this.control);

            this.control.enable();
            this.loading$.next(false);
          },
        });
      },
    };

    this.save.emit(emit);
  }

  cancel() {
    this.readonly();
    this.control.reset();
    this.control.setValue(this.default);
  }
}
