import { Injectable } from '@angular/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import { KeycloakEventTypeLegacy, KeycloakService } from 'keycloak-angular';
import type { KeycloakTokenParsed } from 'keycloak-js';
import { defer, of } from 'rxjs';

export interface TokenClaim extends KeycloakTokenParsed {
  poolId: string;
  poolName: string;
}

@Injectable({
  providedIn: 'root',
})
export class KeycloakAuthService {
  logoutUrl: string | undefined;

  constructor(private keycloakService: KeycloakService) {
    this.init();
  }

  userRoles$ = defer(() =>
    of(
      (
        this.keycloakService.getKeycloakInstance()?.tokenParsed as TokenClaim
      )?.realm_access?.roles.filter(role => !!role) ?? []
    )
  );

  private init() {
    this.keycloakService.keycloakEvents$.subscribe({
      next: event => {
        if (event.type === KeycloakEventTypeLegacy.OnTokenExpired) {
          this.refreshToken();
        }
      },
    });

    setInterval(() => {
      const isExpired = this.keycloakService.isTokenExpired();
      console.log('Token Auto/Manaul check; Expired:', isExpired);
      if (isExpired) {
        this.refreshToken();
      }
    }, 60000);
  }

  private async refreshToken() {
    try {
      const refreshed = await this.keycloakService.updateToken(5);
      if (!refreshed) {
        throw new Error('Token refresh failed');
      }
    } catch (error) {
      console.log('Session not active, user should be logged out', error);
      this.logout();
    }

    const isExpired = this.keycloakService.isTokenExpired();
    if (isExpired) {
      console.log('Session not active, user should be logged out');
      this.logout();
    }
  }

  logout() {
    window.localStorage.clear();
    window.sessionStorage.clear();
    this.keycloakService.logout(this.logoutUrl ?? window.location.origin);
  }
}
