import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ui-footer',
  imports: [CommonModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  @Input({ required: true }) public clientVersion!: string;

  @Input({ required: true }) public backendVersion!: string;

  @Input({ required: true }) public backendStage!: string;

  protected get clientVersionShort() {
    return this.formatVersion(this.clientVersion);
  }

  protected get backendVersionShort() {
    return this.formatVersion(this.backendVersion);
  }

  private formatVersion(buildVersion: string) {
    return buildVersion.substr(0, 8);
  }
}
