import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class CustomIconsService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  init() {
    const icons = [
      'add',
      'calendar',
      'close',
      'context',
      'copy',
      'direction-down',
      'direction-left',
      'direction-right',
      'direction-up',
      'drag',
      'edited',
      'euro',
      'mail',
      'percantage',
      'profile',
      'search',
      'size',
      'substract',
      'weight',
      'check',
      'bell',
      'burger',
      'home',
      'clients',
      'tasks',
      'view',
      'sort',
      'reset',
      'print',
      'info',
      'pin',
      'close-outline',
      'close-filled',
      'check-outline',
      'check-filled',
      'check-filled-rec',
      'advice',
      'download',
      'upload',
      'fullfill',
      'send',
      'product-berufsunfaehigkeit',
      'product-grundfaehigkeit',
      'product-risikoleben',
      'product-kfz',
      'tarifart-starter',
      'tarifart-normal',
      'advice',
      'filter',
      'sign',
      'success',
      'successWithToDo',
      'play',
      'pending',
      'link',
      'dev',
      'qa',
    ];

    icons.forEach(icon => {
      this.matIconRegistry.addSvgIcon(
        `cp-${icon}`,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/${icon}.svg`)
      );
    });
  }
}
