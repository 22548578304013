import type { OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  forwardRef,
  inject,
} from '@angular/core';

import type { ValidationErrors, Validator } from '@angular/forms';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { ControlValueAccessorDirective } from '../../directives/control-value-accessor.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { InputErrorsComponent } from '../input-errors/input-errors.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

type itemValue = number | null;

interface iResponse {
  min: itemValue;
  max: itemValue;
}

@Component({
  selector: 'ui-num-num-input',
  standalone: true,
  imports: [ReactiveFormsModule, MatInputModule, MatFormFieldModule, InputErrorsComponent],
  templateUrl: './num-num-input.component.html',
  styleUrls: ['./num-num-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumNumInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NumNumInputComponent),
      multi: true,
    },
  ],
})
export class NumNumInputComponent
  extends ControlValueAccessorDirective<iResponse>
  implements Validator, OnInit
{
  private _destroyRef = inject(DestroyRef);

  @Input() label!: string;
  @Input() equalallowed!: boolean;

  ctrl: {
    [K in keyof iResponse]: FormControl<itemValue>;
  } = {
    min: new FormControl<itemValue>(null),
    max: new FormControl<itemValue>(null),
  };

  fg = new FormGroup(this.ctrl, {
    validators: (): ValidationErrors | null => {
      const minControl = this.ctrl.min;
      const maxControl = this.ctrl.max;

      const minValue = minControl.value;
      const maxValue = maxControl.value;

      if (maxValue !== null && minValue !== null) {
        minControl.setErrors(
          (this.equalallowed ? minValue <= maxValue : minValue < maxValue)
            ? null
            : {
                max: {
                  max: maxValue - (this.equalallowed ? 0 : 1),
                },
              }
        );

        maxControl.setErrors(
          (this.equalallowed ? minValue <= maxValue : minValue < maxValue)
            ? null
            : {
                min: {
                  min: minValue + (this.equalallowed ? 0 : 1),
                },
              }
        );
      }

      return null;
    },
  });

  override ngOnInit(): void {
    super.ngOnInit();

    if (this.control.disabled) {
      this.ctrl.min.disable();
      this.ctrl.max.disable();
    }

    this.fg.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(value => {
      this.onChange(value as iResponse);
    });
  }

  override writeValue(value: iResponse): void {
    super.writeValue(value);
    if (value) {
      if (value.min !== this.ctrl.min.value) {
        this.ctrl.min.setValue(value.min);
      }
      if (value.max !== this.ctrl.max.value) {
        this.ctrl.max.setValue(value.max);
      }
    }
  }

  validate() {
    if (this.fg.invalid) {
      return { invalid: true };
    }
    return null;
  }
}
