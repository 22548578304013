<ng-template #onlyForm>
  <ng-content />
</ng-template>

@if (enabled) {
  @if ({ edit: editStatus$ | async, changed: valueChanged$ | async }; as state) {
    <div
      [class.padding]="padding"
      [class.pending]="loading$ | async"
      [class.show-actions-on-hover]="enableReadonlyMode"
    >
      <form [formGroup]="fg" (submit)="doSave()" novalidate>
        @if (readChild && state.edit === false) {
          <ng-container *ngTemplateOutlet="readChild" />
        } @else {
          <ng-container *ngTemplateOutlet="onlyForm" />
        }
        <div
          [class.force-show-actions]="state.changed"
          class="actions flex justify-end items-center"
        >
          @if (loading$ | async) {
            <mat-spinner [diameter]="20" />
          } @else {
            @if (!state.edit) {
              <button type="button" (click)="readonly()">
                <mat-icon svgIcon="cp-edited" />
              </button>
            } @else {
              <button type="button" (click)="cancel()">
                <mat-icon svgIcon="cp-close" />
              </button>
              <button type="submit">
                <mat-icon svgIcon="cp-check" />
              </button>
            }
          }
        </div>
      </form>
    </div>
  }
} @else {
  <ng-container *ngTemplateOutlet="onlyForm" />
}
