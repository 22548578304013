import type { MatFormFieldDefaultOptions } from '@angular/material/form-field';
import type { ErrorStateMatcher } from '@angular/material/core';
import type { AbstractControl, FormControl } from '@angular/forms';
import type { ErrorHandler } from '@angular/core';
import { Injectable, InjectionToken, NgZone, inject } from '@angular/core';
import { ToastService } from '../services/toast.service';
import type {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import type { Observable } from 'rxjs';
import {
  ReplaySubject,
  catchError,
  debounceTime,
  distinctUntilChanged,
  firstValueFrom,
  throwError,
} from 'rxjs';
import type { Route } from '@angular/router';
import { faro } from '@grafana/faro-web-sdk';
// eslint-disable-next-line import/no-extraneous-dependencies

/**
 * Typescipt helper
 */

// https://dev.to/maxime1992/implement-a-generic-oneof-type-with-typescript-22em
export type ValueOf<Obj> = Obj[keyof Obj];

type FormControlMap = { [key: string]: FormControl<unknown> };

type PrependSlash<S extends string | undefined> = S extends string
  ? S extends ''
    ? ''
    : `/${S}`
  : '';

export type RoutePath<R extends Route> = R extends { children: Array<infer S extends Route> }
  ? `${PrependSlash<R['path']>}${RoutePath<S>}`
  : PrependSlash<R['path']>;

/**
 * Func
 */

export const IS_DEVELOP_TOKEN = new InjectionToken<boolean>('isDevelop');

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  returnError$ = new ReplaySubject<Error>();

  isDevelop = inject(IS_DEVELOP_TOKEN);

  constructor(
    private _toastService: ToastService,
    private _ngZone: NgZone
  ) {
    this.returnError$.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(error => {
      if (error.message) {
        // this._toastService.show('Es gibt scheinbar Probleme auf unserer Seite.');
      }

      console.error(error);
    });
  }

  handleError(error: Error) {
    if (error instanceof Error) {
      faro.api?.pushError(error);
    }
    this.returnError$.next(error);
  }
}

@Injectable({ providedIn: 'root' })
export class ErrorIntercept implements HttpInterceptor {
  readonly _toastService = inject(ToastService);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else if (Array.isArray(error.error)) {
          // fluent validation error
          errorMessage = error.error.map(o => o.errorMessage).join(', ');
        } else {
          // server-side error
          errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
        }

        // Human info.
        if (errorMessage !== '') {
          this._toastService.show(errorMessage);
        }
        return throwError(() => Error(errorMessage));
      })
    );
  }
}

export const MAT_FORM_FIELD_OPTIONS: MatFormFieldDefaultOptions = {
  appearance: 'outline',
  floatLabel: 'auto',
  subscriptSizing: 'dynamic', // for long error texts & better grid handling in forms
  color: 'primary',
};

export class GlobalErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: AbstractControl | null
    //form: FormGroupDirective | NgForm | null
  ) {
    return (control?.invalid && control?.dirty) ?? false;
  }
}

export const scrollToInvalidFormElement = async (ngZone: NgZone) => {
  await firstValueFrom(ngZone.onStable);
  document.querySelector('.ng-invalid')?.scrollIntoView({ behavior: 'smooth' });
};

export function objectEquals(x: any, y: any): boolean {
  'use strict';

  if (x === null || x === undefined || y === null || y === undefined) {
    return x === y;
  }
  // after this just checking type of one would be enough
  if (x.constructor !== y.constructor) {
    return false;
  }
  // if they are functions, they should exactly refer to same one (because of closures)
  if (x instanceof Function) {
    return x === y;
  }
  // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
  if (x instanceof RegExp) {
    return x === y;
  }
  if (x === y || x.valueOf() === y.valueOf()) {
    return true;
  }

  if (Array.isArray(x)) {
    x = x.sort();
    y = y.sort();
  }

  if (Array.isArray(x) && x.length !== y.length) {
    return false;
  }

  // if they are dates, they must had equal valueOf
  if (x instanceof Date) {
    return false;
  }

  // if they are strictly equal, they both need to be object at least
  if (!(x instanceof Object)) {
    return false;
  }
  if (!(y instanceof Object)) {
    return false;
  }

  // recursive object equality check
  const p = Object.keys(x);
  return (
    Object.keys(y).every(function (i) {
      return p.indexOf(i) !== -1;
    }) &&
    p.every(i => {
      return objectEquals(x[i], y[i]);
    })
  );
}

export function checkPreviousValues<T extends { ctrl?: FormControlMap }>(
  newForm: T,
  oldForm?: T
): void {
  const oldCtrl = oldForm?.ctrl;
  const newCtrl = newForm?.ctrl;
  if (oldCtrl && newCtrl) {
    Object.keys(newCtrl).forEach(key => {
      const formControlKey = key as keyof typeof oldCtrl;

      if (
        oldCtrl[formControlKey] &&
        oldCtrl[formControlKey].value !== oldCtrl[formControlKey].defaultValue
      ) {
        newCtrl[formControlKey].setValue(oldCtrl[formControlKey].value);
      }
    });
  }
}

// https://stackoverflow.com/questions/17415579/how-to-iso-8601-format-a-date-with-timezone-offset-in-javascript
export function toIsoString(date: Date) {
  const tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function (num: number) {
      return (num < 10 ? '0' : '') + num;
    };

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' +
    pad(Math.abs(tzo) % 60)
  );
}
